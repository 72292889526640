<template>
  <v-row v-if="isReportVisible && filteredTicketList.length > 0">
    <v-col>
      <v-data-table :headers="headers" :items="filteredTicketList" :items-per-page="-1" fixed-header>
        <!-- Ticket Hours -->
        <template #[`item.ticketHours`]="{ item }">
          {{ item.chargableProduct == 'FLUSH' ? null : item.ticketHours }}
        </template>

        <!-- Ticket Date -->
        <template #[`item.ticketDate`]="{ item }">
          {{ formatDate(item.ticketDate) }}
        </template>

        <!-- Subtotal Before FS -->
        <template #[`item.subtotalBeforeFS`]="{ item }">
          {{ item.chargableProduct == 'FLUSH' ? null : `$${item.subtotalBeforeFS}` }}
        </template>

        <!-- Fuel Surcharge % -->
        <template #[`item.fuelSurcharge`]="{ item }">
          {{ item.chargableProduct == 'FLUSH' ? null : `${(item.fuelSurcharge * 100).toFixed(2)}%` }}
        </template>

        <!-- Fuel Surcharge Subtotal -->
        <template #[`item.fuelSurchargeSubtotal`]="{ item }">
          {{ item.chargableProduct == 'FLUSH' ? null : `$${item.fuelSurchargeSubtotal}` }}
        </template>

        <!-- Rate -->
        <template #[`item.rate`]="{ item }">
          {{ item.chargableProduct == 'FLUSH' ? null : `$${item.rate}` }}
        </template>

        <!-- Line Total -->
        <template #[`item.lineTotal`]="{ item }">
          {{ item.chargableProduct == 'FLUSH' ? null : `$${item.lineTotal}` }}
        </template>

        <!-- Commission -->
        <template #[`item.commissionRate`]="{ item }">
          {{ item.chargableProduct == 'FLUSH' ? null : `${item.commissionRate * 100}%` }}
        </template>

        <!-- Discount -->
        <template #[`item.discount`]="{ item }">
          {{ item.chargableProduct == 'FLUSH' ? null : `$${item.discount}` }}
        </template>

        <!-- Subtotal -->
        <template #[`item.subtotal`]="{ item }">
          {{ item.chargableProduct == 'FLUSH' ? null : `$${item.subtotal}` }}
        </template>

        <!-- GST -->
        <template #[`item.gst`]="{ item }">
          {{ item.chargableProduct == 'FLUSH' ? null : `$${item.gst}` }}
        </template>

        <!-- Total -->
        <template #[`item.total`]="{ item }">
          {{ item.chargableProduct == 'FLUSH' ? null : `$${item.total}` }}
        </template>

        <!-- PDF -->
        <template #[`item.ticketPdf`]="{ item }">
          <v-icon v-if="hasPdf[item.ticketNum]" class="ml-2" @click="downloadTicketPDF(item.ticketNum)">{{
            pdfIcon
          }}</v-icon>
        </template>

        <!-- Contractor Approval Status -->
        <!-- TODO: Add invoice number to tooltip -->
        <template #[`item.contractorApproved`]="{ item }">
          <v-tooltip
            top
            :disabled="
              !item.contractorInvoiced &&
              (item.jobApproved || (item.ticketCreatedBeforeLastJobEdit && item.jobHasBeenCompletedBefore))
            "
          >
            <template v-slot:activator="{ on }">
              <div v-on="on">
                <v-checkbox
                  :disabled="
                    item.contractorInvoiced ||
                    (!item.jobApproved && (!item.ticketCreatedBeforeLastJobEdit || !item.jobHasBeenCompletedBefore))
                  "
                  v-model="item.contractorApproved"
                  @click="
                    updateContractorApproval(`${item.ticketNum}`, item.contractorApproved, item.contractorInvoiced)
                  "
                ></v-checkbox>
              </div>
            </template>
            <span>{{ getCheckboxMessage(item.contractorInvoiced, item.jobApproved, item.jobID) }}</span>
          </v-tooltip>
        </template>

        <!-- Dispute Button -->
        <template #[`item.dispute`]="{ item }">
          <v-btn
            small
            color="#661E1B"
            class="btn-dispute"
            @click="openDisputeModal(item)"
            :disabled="
              disputedTickets.includes(item.ticketNum) ||
              item.contractorApproved === true ||
              item.contractorInvoiced === true
            "
            >{{ disputedTickets.includes(item.ticketNum) ? 'Disputed' : 'Dispute' }}</v-btn
          >
        </template>

        <!-- Contractor Invoiced Status -->
        <template #[`item.contractorInvoiced`]="{ item }">
          <!-- <v-tooltip top> -->
          <v-chip small :color="`${item.contractorInvoiced ? '#37665a' : '#E0B341'}`">
            {{ item.contractorInvoiced ? 'Invoiced' : 'Awaiting Invoice' }}
          </v-chip>
        </template>
      </v-data-table>
    </v-col>
    <!-- Dispute modal -->
    <v-dialog v-model="disputeModal" max-width="500" @input="resetDisputeFormValidation">
      <v-card class="pt-6 pb-2 pr-8 pl-8">
        <p>Dispute for ticket: #{{ ticketNum }}</p>
        <v-textarea
          ref="textarea"
          v-model="disputeReason"
          label="Reason for Dispute"
          :rules="[rules.required]"
          outlined
          dense
        ></v-textarea>
        <v-col class="text-right">
          <v-btn color="#37665a" class="text-lg-right" @click="addDisputeforTicket()">
            <span v-if="!emailLoading">Submit</span>
            <half-circle-spinner v-else :animation-duration="1000" :size="20" :color="'white'" />
          </v-btn>
        </v-col>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
const axios = require('axios').default

import { mdiFileDownload } from '@mdi/js'
import firebase from 'firebase'
import 'firebase/storage'
import moment from 'moment'

export default {
  props: {
    ticketList: Array,
    isReportVisible: Boolean,
    showApprovedTickets: Boolean,
  },

  data() {
    return {
      headers: [
        { text: 'PDF', value: 'ticketPdf' },
        { text: 'Ticket #', value: 'ticketNum' },
        { text: 'Ticket Date', value: 'ticketDate', width: '10%' },
        { text: 'Job', value: 'jobID' },
        { text: 'Operations', value: 'operations' },
        { text: 'Unit', value: 'unitID' },
        { text: 'Customer', value: 'customer' },
        { text: 'Product', value: 'product' },
        { text: 'Ticket Hours', value: 'ticketHours' },
        { text: 'Hours', value: 'hours' },
        { text: 'Rate', value: 'rate' },
        { text: 'Subtotal Before Fuel Surch.', value: 'subtotalBeforeFS' },
        { text: 'Fuel Surch. %', value: 'fuelSurcharge' },
        { text: 'Fuel Surch.', value: 'fuelSurchargeSubtotal' },
        { text: 'Subtotal', value: 'subtotal' },
        { text: 'Comm. Rate', value: 'commissionRate' },
        { text: 'Comm.', value: 'discount' },
        { text: 'Subtotal (After Comm.)', value: 'lineTotal' },
        { text: 'GST', value: 'gst' },
        { text: 'Total', value: 'total' },
        { text: 'Approve', value: 'contractorApproved', width: '2%' },
        { text: 'Dispute', value: 'dispute' },
        { text: 'Invoiced', value: 'contractorInvoiced' },
      ],
      disputeModal: false,
      disputeReason: null,
      emailLoading: false,
      ticketNum: null,
      userData: JSON.parse(sessionStorage.userData),
      selectedCompany: sessionStorage.selectedOrg,
      disputeDate: new Date(),
      orgData: null,
      ticketData: null,
      rules: {
        required: value => !!value || 'Required.',
      },
      disputedTickets: [],
      pdfIcon: mdiFileDownload,
      hasPdf: {},
      uploadFolderPath: 'ticketPDFs',
      checkedPdfs: [],
    }
  },
  methods: {
    formatDate: function (date) {
      return moment(date).format('MMM DD, YY')
    },
    getCheckboxMessage(contractorInvoiced, jobApproved, jobID) {
      if (contractorInvoiced) {
        return `This ticket has already been invoiced`
      } else if (!jobApproved) {
        return `Job #${jobID} is currently under review. You can only approve tickets when the job is approved or if the job has been approved once before and this ticket was created before the last time the job was updated.`
      }
    },
    updateContractorApproval: function (ticketNum, approvalState, invoicedState) {
      if (invoicedState === true) {
        return
      }
      try {
        let userData = JSON.parse(sessionStorage.userData)

        axios
          .post(
            // 'https://localhost:44370/api/Tickets/update-contractor-approval',
            'https://wilardapi.azurewebsites.net/api/Tickets/update-contractor-approval',
            {
              ticketNuM: ticketNum,
              approved: approvalState === true ? 'Yes' : 'No',
              approvedBy: userData.name,
            },
            {
              headers: { ApiKey: process.env.VUE_APP_WILARD_API_KEY },
              // headers: { ApiKey: 'd&EJ5&VaCyyN8wZAs' },
            },
          )
          .then(response => {
            if (response.data === 1) {
              //success
              this.$toasted.show(approvalState === true ? 'Ticket approved' : 'Ticket unapproved', {
                duration: '5000',
                keepOnHover: true,
                type: 'success',
              })
              this.updateUnapprovedTicketCount()
            } else {
              this.$toasted.show('Error changing approval status of ticket', {
                duration: null,
                keepOnHover: true,
                type: 'error',
              })
            }
          })
          .catch(err => {
            console.log(`Error calling get customers network request: ${err}`)
            this.$toasted.show('Error changing approval status of ticket', {
              duration: null,
              keepOnHover: true,
              type: 'error',
            })
          })
      } catch (err) {
        console.log(`Error updating approval status: ${err}`)
        this.$toasted.show('Error changing approval status of ticket', {
          duration: null,
          keepOnHover: true,
          type: 'error',
        })
      }
    },
    updateUnapprovedTicketCount() {
      //check to see if there are any tickets that are not approved. If there are, then set the count so the user cannot submit an invoice.
      this.$emit('updateApprovedTicketCount', {
        unapprovedTicketCount: this.ticketList.filter(o => o.contractorApproved === false).length,
      })
    },
    openDisputeModal(ticket) {
      this.disputeModal = true
      this.ticketNum = ticket.ticketNum
      this.ticketData = ticket
    },
    async fetchDisputedTicketsStatus() {
      const snapshot = await firebase.firestore().collection('disputedTickets').get()
      snapshot.forEach(doc => {
        const data = doc.data()
        if (data && this.ticketList.some(ticket => ticket.ticketNum === data.ticketNum)) {
          this.disputedTickets.push(data.ticketNum) // Aktualizacja stanu
        }
      })
    },

    addDisputeforTicket: async function () {
      try {
        if (!this.disputeReason || this.disputeReason.trim() === '') {
          this.showError('Reason for dispute cannot be empty')
        } else {
          let disputeReason = this.disputeReason
          let addData = {
            ticketNum: this.ticketNum,
            userData: this.userData,
            org: this.selectedCompany,
            date: this.disputeDate,
            disputeReason: disputeReason,
          }

          await firebase
            .firestore()
            .collection('disputedTickets')
            .add(addData)
            .then(async () => {
              this.disputeModal = false
              this.disputedTickets.push(this.ticketNum)
              this.sendDisputeEmail()
              await this.disputeTicketInModifiedTickets(disputeReason)
            })
        }
      } catch (err) {
        console.log(`Error adding dispute: ${err}`)
        this.showError('Error adding dispute')
      }
    },
    sendDisputeEmail: async function () {
      try {
        let formatedDisputeDate = moment(this.disputeDate).format('MMMM D, [at] hh:mm:ss A [UTC]Z')
        let data = {
          userData: this.userData,
          disputeReason: this.disputeReason,
          ticketNum: this.ticketNum,
          disputeDate: formatedDisputeDate,
          companyName: this.orgData.name,
          ticketData: this.ticketData,
        }
        let response = await axios.get(
          // 'http://127.0.0.1:5001/jdcservices-69fd4/us-central1/disputeTicketEmail',
          'https://us-central1-jdcservices-69fd4.cloudfunctions.net/disputeTicketEmail',
          {
            params: { data },
          },
        )
        if (response.status === 200) {
          this.showSuccess('Dispute email has been sent.')
        } else {
          this.showError('Error sending dispute email.')
        }
      } catch (error) {
        this.showError('Error sending dispute email network request.')
        this.emailLoading = false
      }
    },
    getOrgData: function () {
      try {
        firebase
          .firestore()
          .collection('orgs')
          .doc(this.selectedCompany)
          .get()
          .then(doc => {
            if (doc.exists) {
              this.orgData = doc.data()
            }
          })
          .catch(err => {
            console.log(`Error executing get org data function: ${err}`)
            this.showError('Error sending organization info')
          })
      } catch (err) {
        console.log(`Error getting org data: ${err}`)
        this.showError('Error sending org data')
      }
    },
    showSuccess: function (message) {
      this.$toasted.show(message, {
        duration: '9000',
        keepOnHover: true,
        type: 'success',
      })
    },
    showError: function (message) {
      this.$toasted.show(message, {
        duration: '9000',
        keepOnHover: true,
        type: 'error',
      })
    },
    resetDisputeFormValidation(value) {
      if (value === false) {
        this.$nextTick(() => {
          this.$refs.textarea.resetValidation()
        })
      }
    },
    disputeTicketInModifiedTickets: async function (disputeReason) {
      firebase
        .firestore()
        .collection('modifiedTickets')
        .where('ticketNum', '==', this.ticketNum)
        .get()
        .then(querySnapshot => {
          if (!querySnapshot.empty) {
            querySnapshot.forEach(doc => {
              let changes = doc.data().changes
              if (changes.length > 0) {
                changes.forEach(change => {
                  change.approved = {
                    date: this.disputeDate,
                    disputeReason: disputeReason,
                    userID: this.userData.uid,
                    userName: this.userData.name,
                    value: false,
                  }
                })
              }
              const ticketRef = doc.ref
              ticketRef
                .update({
                  isDisputed: true,
                  changes: changes,
                })
                .catch(error => {
                  console.error('Error updating ticket:', error)
                })
            })
          } else {
            // No ticket found
            console.log('No ticket found with the specified ticket number.')
          }
        })
        .catch(error => {
          console.error('Error getting ticket:', error)
        })
    },
    downloadTicketPDF(ticketNumber) {
      fetch(this.hasPdf[ticketNumber])
        .then(response => {
          return response.blob()
        })
        .then(blob => {
          const localUrl = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.style.display = 'none'
          a.href = localUrl
          a.download = `${ticketNumber}.pdf`
          document.body.appendChild(a)
          a.click()
          window.URL.revokeObjectURL(localUrl)
          document.body.removeChild(a)
        })
        .catch(error => {
          console.error('Error fetching the PDF: ', error)
        })
        .catch(error => {
          console.error('Error getting the download URL: ', error)
        })
    },
    checkPdfExists(ticketNum) {
      const storageRef = firebase.storage().ref()
      const fileRef = storageRef.child(`${this.uploadFolderPath}/${ticketNum}.pdf`)
      fileRef
        .getDownloadURL()
        .then(url => {
          this.$set(this.hasPdf, ticketNum, url)
        })
        .catch(error => {
          this.$set(this.hasPdf, ticketNum, false)
        })
    },
  },
  watch: {
    ticketList(newList, oldList) {
      this.updateUnapprovedTicketCount()
      this.fetchDisputedTicketsStatus()
      newList.forEach(item => {
        if (this.checkedPdfs.includes(item.ticketNum)) {
          return
        }
        this.checkedPdfs.push(item.ticketNum)
        this.checkPdfExists(item.ticketNum)
      })
    },
    disputeModal(newVal) {
      if (!newVal) {
        this.disputeReason = ''
      }
    },
  },
  computed: {
    filteredTicketList() {
      if (!this.ticketList.length) return []
      return this.showApprovedTickets ? this.ticketList : this.ticketList.filter(item => !item.contractorApproved)
    },
  },
  mounted() {
    this.getOrgData()
  },
}
</script>
