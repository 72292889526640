var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isReportVisible && _vm.filteredTicketList.length > 0)?_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredTicketList,"items-per-page":-1,"fixed-header":""},scopedSlots:_vm._u([{key:"item.ticketHours",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.chargableProduct == 'FLUSH' ? null : item.ticketHours)+" ")]}},{key:"item.ticketDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.ticketDate))+" ")]}},{key:"item.subtotalBeforeFS",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.chargableProduct == 'FLUSH' ? null : ("$" + (item.subtotalBeforeFS)))+" ")]}},{key:"item.fuelSurcharge",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.chargableProduct == 'FLUSH' ? null : (((item.fuelSurcharge * 100).toFixed(2)) + "%"))+" ")]}},{key:"item.fuelSurchargeSubtotal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.chargableProduct == 'FLUSH' ? null : ("$" + (item.fuelSurchargeSubtotal)))+" ")]}},{key:"item.rate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.chargableProduct == 'FLUSH' ? null : ("$" + (item.rate)))+" ")]}},{key:"item.lineTotal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.chargableProduct == 'FLUSH' ? null : ("$" + (item.lineTotal)))+" ")]}},{key:"item.commissionRate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.chargableProduct == 'FLUSH' ? null : ((item.commissionRate * 100) + "%"))+" ")]}},{key:"item.discount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.chargableProduct == 'FLUSH' ? null : ("$" + (item.discount)))+" ")]}},{key:"item.subtotal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.chargableProduct == 'FLUSH' ? null : ("$" + (item.subtotal)))+" ")]}},{key:"item.gst",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.chargableProduct == 'FLUSH' ? null : ("$" + (item.gst)))+" ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.chargableProduct == 'FLUSH' ? null : ("$" + (item.total)))+" ")]}},{key:"item.ticketPdf",fn:function(ref){
var item = ref.item;
return [(_vm.hasPdf[item.ticketNum])?_c('v-icon',{staticClass:"ml-2",on:{"click":function($event){return _vm.downloadTicketPDF(item.ticketNum)}}},[_vm._v(_vm._s(_vm.pdfIcon))]):_vm._e()]}},{key:"item.contractorApproved",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","disabled":!item.contractorInvoiced &&
            (item.jobApproved || (item.ticketCreatedBeforeLastJobEdit && item.jobHasBeenCompletedBefore))},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-checkbox',{attrs:{"disabled":item.contractorInvoiced ||
                  (!item.jobApproved && (!item.ticketCreatedBeforeLastJobEdit || !item.jobHasBeenCompletedBefore))},on:{"click":function($event){return _vm.updateContractorApproval(("" + (item.ticketNum)), item.contractorApproved, item.contractorInvoiced)}},model:{value:(item.contractorApproved),callback:function ($$v) {_vm.$set(item, "contractorApproved", $$v)},expression:"item.contractorApproved"}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getCheckboxMessage(item.contractorInvoiced, item.jobApproved, item.jobID)))])])]}},{key:"item.dispute",fn:function(ref){
                  var item = ref.item;
return [_c('v-btn',{staticClass:"btn-dispute",attrs:{"small":"","color":"#661E1B","disabled":_vm.disputedTickets.includes(item.ticketNum) ||
            item.contractorApproved === true ||
            item.contractorInvoiced === true},on:{"click":function($event){return _vm.openDisputeModal(item)}}},[_vm._v(_vm._s(_vm.disputedTickets.includes(item.ticketNum) ? 'Disputed' : 'Dispute'))])]}},{key:"item.contractorInvoiced",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":("" + (item.contractorInvoiced ? '#37665a' : '#E0B341'))}},[_vm._v(" "+_vm._s(item.contractorInvoiced ? 'Invoiced' : 'Awaiting Invoice')+" ")])]}}],null,true)})],1),_c('v-dialog',{attrs:{"max-width":"500"},on:{"input":_vm.resetDisputeFormValidation},model:{value:(_vm.disputeModal),callback:function ($$v) {_vm.disputeModal=$$v},expression:"disputeModal"}},[_c('v-card',{staticClass:"pt-6 pb-2 pr-8 pl-8"},[_c('p',[_vm._v("Dispute for ticket: #"+_vm._s(_vm.ticketNum))]),_c('v-textarea',{ref:"textarea",attrs:{"label":"Reason for Dispute","rules":[_vm.rules.required],"outlined":"","dense":""},model:{value:(_vm.disputeReason),callback:function ($$v) {_vm.disputeReason=$$v},expression:"disputeReason"}}),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{staticClass:"text-lg-right",attrs:{"color":"#37665a"},on:{"click":function($event){return _vm.addDisputeforTicket()}}},[(!_vm.emailLoading)?_c('span',[_vm._v("Submit")]):_c('half-circle-spinner',{attrs:{"animation-duration":1000,"size":20,"color":'white'}})],1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }