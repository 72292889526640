<template>
  <v-card class="ml-3 mr-3 mb-3">
    <v-card-title class="text-center">
      {{ statTitle }}
    </v-card-title>
    <v-card-text class="text-center text-xl">
      {{ total }}
    </v-card-text>
    <!-- <v-card-text class="text-no-wrap text--primary mt-3">
      {{ statTitle }}
    </v-card-text> -->
  </v-card>
</template>

<script>
export default {
  props: {
    statTitle: { type: String, default: '' },
    icon: { type: String, default: '' },
    color: { type: String, default: '' },
    total: { type: String, default: '' },
  },
}
</script>

<style lang="scss" scoped>
.percentage {
  top: -8px;
  position: relative;
}
</style>
