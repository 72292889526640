import { roundTo } from '@/js/helpers.js'
import firebase from 'firebase'
import moment from 'moment'

export async function generateTicketList(ticketList) {
  try {
    // Exclude any tickets that have been created in the last 6 hours
    const today = moment(Date.now())
    const filteredTicketList = ticketList.filter(item => {
      const created = moment(item.timeCreated)
      const duration = moment.duration(today.diff(created))
      const hours = duration.asHours()
      return hours > 6
    })

    // Group the tickets in separate arrays by ticket number
    const groupedTickets = filteredTicketList.reduce((acc, item) => {
      if (!acc[item.ticketNum]) {
        acc[item.ticketNum] = []
      }
      acc[item.ticketNum].push(item)
      return acc
    }, {})

    // Process each group of tickets
    const promises = Object.values(groupedTickets).map(async charges => {
      let overridePercentage = await getOverridePercentageByUnitID(charges[0].unitID)
      overridePercentage = overridePercentage ? overridePercentage / 100 : null

      if (charges.length === 2) {
        // Check if the products are hourly rate/m3 and fuel surcharge, if they are, combine them into one ticket
        const hourlyOrCubeIndex = charges.findIndex(o => o.product === 'Hourly Rate' || o.product === 'm³ Rate')
        const fsIndex = charges.findIndex(o => o.product === 'Fuel Surcharge %')
        if (hourlyOrCubeIndex !== -1 && fsIndex !== -1) {
          const hourlyOrCubeCharge = charges[hourlyOrCubeIndex]
          const fsCharge = charges[fsIndex]
          const sumOfTotals = roundTo(hourlyOrCubeCharge.lineTotal + fsCharge.lineTotal, 2)

          let discount = overridePercentage
            ? roundTo(sumOfTotals * overridePercentage, 2)
            : roundTo(sumOfTotals * hourlyOrCubeCharge.commissionRate, 2)

          return {
            goToJob: [hourlyOrCubeCharge.jobID, hourlyOrCubeCharge.ticketNum],
            product: hourlyOrCubeCharge.product,
            contractorApproved: hourlyOrCubeCharge.contractorApproved === 'Yes',
            contractorInvoiced: hourlyOrCubeCharge.contractorInvoiced === 'Yes',
            jobApproved: hourlyOrCubeCharge.jobApproved === 'Yes',
            ticketNum: hourlyOrCubeCharge.ticketNum,
            unitID: hourlyOrCubeCharge.unitID,
            ticketDate: moment(hourlyOrCubeCharge.ticketDate).format('MMM DD, YY'),
            jobID: hourlyOrCubeCharge.jobID,
            ticketHours: hourlyOrCubeCharge.ticketHours,
            fluidHauled: hourlyOrCubeCharge.fluidHauled,
            customer: hourlyOrCubeCharge.customer,
            operations: hourlyOrCubeCharge.operations,
            hours: Number(hourlyOrCubeCharge.chargableProduct),
            rate: hourlyOrCubeCharge.rate,
            subtotalBeforeFS: roundTo(hourlyOrCubeCharge.chargableProduct * hourlyOrCubeCharge.rate, 3),
            fuelSurcharge: fsCharge.chargableProduct,
            fuelSurchargeSubtotal: roundTo(
              fsCharge.chargableProduct * (hourlyOrCubeCharge.chargableProduct * hourlyOrCubeCharge.rate),
              2,
            ),
            subtotal: roundTo(hourlyOrCubeCharge.lineTotal + fsCharge.lineTotal, 3),
            commissionRate: overridePercentage ? overridePercentage : hourlyOrCubeCharge.commissionRate,
            discount: discount,
            lineTotal: roundTo(sumOfTotals - discount, 2),
            gst: roundTo((sumOfTotals - discount) * 0.05, 2),
            total: roundTo((hourlyOrCubeCharge.lineTotal + fsCharge.lineTotal - discount) * 1.05, 2),
          }
        }
      }
      // If there is only one charge, or there are more than 2 charges, or there are 2 charges but they are not hourly rate/m³ rate and fuel surcharge, format and return each charge

      return charges.map(charge => formatTicket(charge, overridePercentage))
    })

    const formattedTicketList = (await Promise.all(promises)).flat()

    return formattedTicketList
  } catch (err) {
    console.log(`Error formatting ticket list: ${err}`)
  }
}

function formatTicket(charge, overridePercentage) {
  const {
    jobID,
    ticketNum,
    product,
    contractorApproved,
    contractorInvoiced,
    jobApproved,
    unitID,
    ticketDate,
    chargableProduct,
    rate,
    ticketHours,
    fluidHauled = 0,
    customer,
    operations,
    commissionRate,
    lineTotal,
    discount,
  } = charge

  const computedOverridePercentage = overridePercentage ? overridePercentage / 100 : commissionRate
  const calculatedDiscount = overridePercentage ? roundTo(lineTotal * overridePercentage, 2) : roundTo(discount, 2)
  const calculatedSubtotalBeforeFS = roundTo(chargableProduct * rate, 2)
  const calculatedLineTotal = roundTo(lineTotal - calculatedDiscount, 2)
  const calculatedGST = roundTo(calculatedLineTotal * 0.05, 2)
  const calculatedTotal = roundTo(calculatedLineTotal * 1.05, 2)

  let ticket = {
    goToJob: [jobID, ticketNum],
    product,
    contractorApproved: contractorApproved === 'Yes',
    contractorInvoiced: contractorInvoiced === 'Yes',
    jobApproved: jobApproved === 'Yes',
    ticketNum,
    unitID,
    ticketDate: moment(ticketDate).format('MMM DD, YY'),
    jobID,
    hours: Number(chargableProduct),
    rate,
    ticketHours,
    fluidHauled,
    customer,
    operations,
    commissionRate: computedOverridePercentage,
    subtotalBeforeFS: calculatedSubtotalBeforeFS,
    fuelSurcharge: 0,
    fuelSurchargeSubtotal: 0,
    subtotal: roundTo(lineTotal, 2),
    discount: calculatedDiscount,
    lineTotal: calculatedLineTotal,
    gst: calculatedGST,
    total: calculatedTotal,
  }

  if (['FLUSH', 'NO CHARGE', 'VOID'].includes(chargableProduct)) {
    ticket = {
      ...ticket,
      product: chargableProduct,
      hours: 0,
      rate: 0,
      subtotalBeforeFS: 0,
      subtotal: 0,
      discount: 0,
      lineTotal: 0,
      gst: 0,
      total: 0,
    }
  } else if (product === 'Fuel Surcharge %') {
    ticket = {
      ...ticket,
      hours: 0,
      rate: 0,
      subtotalBeforeFS: rate,
      fuelSurcharge: Number(chargableProduct),
      fuelSurchargeSubtotal: lineTotal,
    }
  }

  return ticket
}

async function getOverridePercentageByUnitID(unitID) {
  try {
    const unitsRef = firebase.firestore().collection('units')
    const querySnapshot = await unitsRef.where('name', '==', unitID).get()

    if (!querySnapshot.empty) {
      const doc = querySnapshot.docs[0]
      return doc.data().overridePercentage
    }

    return null
  } catch (error) {
    console.error('Error retrieving override percentage:', error)
    throw error
  }
}
